<template>
    <div class="vx-row">
		<div class="vx-col md:w-1/1 w-full">
			<vx-card>
                <span>
                    <h4 class="mb-4" v-if="this.$route.params.id">Edit Role</h4>
                    <h4 class="mb-4" v-else>Add Role</h4>
                </span>
				<div class="vx-row mb-6 ml-4 mr-4">
					<div class="vx-col sm:w-1/5 w-full">
						<span>Name</span>
					</div>
					<div class="vx-col sm:w-4/5 w-full">
						<vs-input v-validate="'required'" name="name" class="w-full" v-model="responseData.name" />
                        <span
                            class="text-danger text-sm"
                            v-show="errors.has('name')"
                            >{{ errors.first('name') }}
                        </span>
					</div>
				</div>
                <div class="vx-row mb-6 ml-4 mr-4">
					<div class="vx-col sm:w-1/5 w-full">
						<span>Display Name</span>
					</div>
					<div class="vx-col sm:w-4/5 w-full">
						<vs-input v-validate="'required'" name="display name" class="w-full" v-model="responseData.displayName" />
                        <span
                            class="text-danger text-sm"
                            v-show="errors.has('display name')"
                            >{{ errors.first('display name') }}
                        </span>
					</div>
				</div>
                <div class="vx-row mb-6 ml-4 mr-4">
					<div class="vx-col sm:w-1/5 w-full">
						<span>Guard Name</span>
					</div>
					<div class="vx-col sm:w-4/5 w-full">
						<vs-input name="guard_name" class="w-full" v-model="responseData.guardName" />
					</div>
				</div>
                <div class="vx-row mb-6 ml-4 mr-4">
					<div class="vx-col sm:w-1/5 w-full">
						<span>Group</span>
					</div>
					<div class="vx-col sm:w-4/5 w-full">
						<v-select v-validate="'required'" name="role group" placeholder="Select role group" :value="responseData.group" @input="setSelected" label="name" :options="options"/>
                        <span
                            class="text-danger text-sm"
                            v-show="errors.has('role group')"
                            >{{ errors.first('role group') }}
                        </span>
                    </div>
				</div>
                <div class="vx-row mb-6 ml-4 mr-4">
					<div class="vx-col sm:w-1/5 w-full">
						<span>Level</span>
					</div>
					<div class="vx-col sm:w-4/5 w-full">
						<vs-input v-validate="'required|numeric'" name="level" class="w-full" v-model="responseData.level" />
                        <span
                            class="text-danger text-sm"
                            v-show="errors.has('level')"
                            >{{ errors.first('level') }}
                        </span>
					</div>
				</div>
				<div class="vx-row mb-6 ml-4 mr-4">
					<div class="vx-col sm:w-4/5 w-full ml-auto">
						<vs-button v-if="this.$route.params.id" v-on:click="handleSubmit" class="mr-3 mb-2">Update</vs-button>
						<vs-button v-else v-on:click="handleSubmit" class="mr-3 mb-2">Create</vs-button>
					</div>
				</div>
			</vx-card>
        </div>
    </div>
</template>

<script>
import vSelect from "vue-select";
export default {
    components: {
        vSelect
    },
    created() {
        this.getData();
        this.getRoleGroup();
    },
    data() {
        return this.initialState();
    },
    methods: {
        getData(){
            this.$vs.loading();
            this.$http
            .get("api/v1/setting/role/" + this.$route.params.id).then(resp => {
                this.$vs.loading.close();
                if (resp.status == "success") {
                    this.responseData.name = resp.data.name;
                    this.responseData.displayName = resp.data.display_name;
                    this.responseData.guardName = resp.data.guard_name;
                    this.responseData.group = resp.data.group;
                    this.responseData.level = resp.data.level;
                }
            });
        },
        setSelected(value) {
            this.responseData.group = value.name;
            // this.responseData.group = value.id;
        },
        getRoleGroup(){
            this.$vs.loading();
            this.$http
            .get("api/v1/setting/role-group").then(resp => {
                this.$vs.loading.close();
                if (resp.status == "success") {
                    this.options = resp.data;
                    // console.log(this.responseData.role)
                }
            });
        },
        initialState() {
            return {
                options: [],
                responseData: {
                    name: "",
                    displayName: "",
                    guardName: "",
                    group: "",
                    level: "",
                },
            };
        },
        handleSubmit() {
            this.$validator.validateAll().then(result => {
                if (result) {
                    this.$vs.loading();
                    if(this.$route.params.id){
                        this.putData();
                    } else {
                        this.postData();
                        console.log(this.paramData())
                    }
                }
            });
        },
        paramData() {
            return {
                name: this.responseData.name,
                displayName: this.responseData.displayName,
                guardName: this.responseData.guardName,
                group: this.responseData.group,
                level: this.responseData.level,
            };
        },
        postData() {
            this.$http
                .post("/api/v1/setting/role", this.paramData())
                .then(resp => {
                this.$vs.loading.close();
                if (resp.code == 200) {
                    this.$vs.notify({
                    color: "success",
                    title: "Success",
                    text: "New User Created",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle"
                    });
                } else {
                    this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: resp.message,
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle"
                    });
                }
                })
                    .catch(error => {
                    this.$vs.loading.close();
                    console.log(error);
                });
        },
        putData() {
            this.$http
            .put("/api/v1/setting/role/" + this.$route.params.id, this.paramData())
            .then(resp => {
            this.$vs.loading.close();
                if (resp.code == 200) {
                    this.$vs.notify({
                        color: "success",
                        title: "Success",
                        text: "Role Updated",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle"
                    });
                } else {
                    this.$vs.notify({
                        color: "danger",
                        title: "Error",
                        text: resp.message,
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle"
                    });
                }
            })
            .catch(error => {
                this.$vs.loading.close();
                console.log(error);
            });
        },
    },
};
</script>